import _ from 'lodash'

const templateTitleDebug = process.env.NODE_ENV === 'development' ? ' Returning:{panel.returning},Caretaker:{panel.caretaker}' : ''

const getContactWizardHelpPropsData = (helpTextKey) => {
    return ({ $store }) => {
        return {
            hint: {
                title: 'Help Message',
                message: $store.getters.scheduleHelpText[helpTextKey]
            }
        }
    }
}

const getBookPropsData = () => {
    return ({ question }) => {
        const attendee0 = _.cloneDeep(_.omit(question.survey.valuesHash, ['attendees']))
        if (!attendee0.returning) {
            delete attendee0.token
        }
        const otherAttendees = question.survey.valuesHash.adding_attendees ? (_.cloneDeep(question.survey.valuesHash.attendees || [])) : []
        otherAttendees.forEach((attendee) => {
            if (!attendee.returning) {
                delete attendee.token
                attendee.phone = attendee.phone || attendee0.phone
                attendee.email = attendee.email || attendee0.email
            }
            if (!attendee.returning && !attendee0.caretaker) {
                attendee.zip_code = attendee.zip_code || attendee0.zip_code
                if (attendee.zip_code === attendee0.zip_code) {
                    attendee.fips = attendee0.fips
                }
                attendee.effective_date = attendee.effective_date || attendee0.effective_date
                attendee.source_name = attendee.source_name || attendee0.source_name
                attendee.source_type = attendee.source_type || attendee0.source_type
                attendee.group = attendee.group || (attendee0.group?.id || attendee0.group)
            }
        })

        // eslint-disable-next-line prefer-const
        let method = attendee0.method || question.survey.valuesHash.method
        const aep = question.survey.valuesHash.aep

        // temporary disable video for non aep
        // if (aep !== false && method === 'video') {
        //     method = 'phone'
        // }

        return {
            method,
            aep,
            notes: attendee0.notes || question.survey.valuesHash.notes,
            agent: question.survey.valuesHash.agent,
            attendees: [
                _.omit(attendee0, ['method', 'notes']),
                ...otherAttendees
            ],
            inWizard: true,
        }
    }
}

const surveyJson = {
    showPageNumbers: false,
    showQuestionNumbers: 'off',
    questionDescriptionLocation: 'underInput',
    questionErrorLocation: 'bottom',
    questionTitlePattern: 'numTitle',
    showProgressBar: 'off',
    _showProgressBar: 'top',
    progressBarType: 'pages',
    goNextPageAutomatic: false,
    calculatedValues: [
        {
            name: 'yearMinus2',
            expression: 'getter("yearMinus2")'
        },
        {
            name: 'emailOptInText',
            expression: 'getter("scheduleHelpTextEmailOptIn")'
        },
        {
            name: 'canAgent',
            expression: 'getter("canAgent")'
        },
        {
            name: 'isAEPScheduling',
            expression: 'getter("isAEPScheduling")'
        },
        {
            name: 'cannotAgent',
            expression: '!getter("canAgent")'
        },
        {
            name: 'preferredFirstName',
            expression: 'preferredFirstName()'
        },
        {
            name: 'hasOtherNewAttendees',
            expression: 'hasOtherNewAttendees()'
        },
        {
            name: 'hasAnyReturning',
            expression: 'hasAnyReturning()'
        },
        {
            name: 'bookingTitle',
            expression: 'x("this?.survey?.valuesHash?.method === \\"email\\" || this?.survey?.valuesHash?.method === \\"survey\\" ? \\"Email Consultation\\" : \\"Select Date & Time\\"")'
        }
    ],
    pages: [
        {
            name: 'initial_page',
            displayName: 'your_name',
            hint: {
                title: 'Help Message',
                // message: 'This is an example of some explanation of feature, data we collect',
                returning: "Great! Let's look up your information",
                returningAttendee: n => `Great! We will look up ${n}'s information on the next page.`,
                nonReturningAttendee: n => `We will ask more information about ${n} on the next couple of pages.`,
                nonReturning: 'Do not worry! We will help you every step of the way!',
                // link: 'some link'
            },
            elements: [
                {
                    type: 'panel',
                    name: 'your_name_panel',
                    elements: [
                        {
                            visibleIf: '{isAEPScheduling}',
                            title: 'Are you currently on Medicare and looking to review your coverage during the Medicare Annual Enrollment Period?',
                            type: 'vcomponent',
                            name: 'aep',
                            component: 'survey/Choices',
                            isRequired: true,
                            skipClasses: true,
                            propsData: ({ $store, $route }) => {
                                return {
                                    choices: [{ title: 'Yes', value: true }, { title: 'No', value: false }],
                                }
                            }
                        },
                        {
                            type: 'vcomponent',
                            name: 'returning',
                            component: 'survey/Choices',
                            title: 'Have you worked with us before?',
                            skipClasses: true,
                            isRequired: true,
                            propsData: () => {
                                return {
                                    choices: [
                                        {
                                            title: 'Yes',
                                            value: true
                                        },
                                        {
                                            title: 'No',
                                            value: false
                                        },
                                    ]
                                }
                            }
                        },
                    ]
                },
                {
                    type: 'panel',
                    name: 'client_name_panel',
                    title: 'What is your full name?',
                    visibleIf: '{returning} = false',
                    elements: [
                        {
                            type: 'text',
                            name: 'first_name',
                            startWithNewLine: false,
                            title: 'First',
                            xtitleLocation: 'hidden',
                            isRequired: true,
                            width: '40%',
                            minWidth: '40%',
                            maxWidth: '40%',
                            placeHolder: 'First'
                        },
                        {
                            type: 'text',
                            name: 'middle_name',
                            startWithNewLine: true,
                            title: 'Middle',
                            xtitleLocation: 'hidden',
                            placeHolder: 'Middle',
                            width: '20%',
                            minWidth: '20%',
                            maxWidth: '20%',
                            defaultValue: ''
                        },
                        {
                            type: 'text',
                            name: 'last_name',
                            title: 'Last',
                            xtitleLocation: 'hidden',
                            width: '40%',
                            minWidth: '40%',
                            maxWidth: '40%',
                            startWithNewLine: true,
                            isRequired: true,
                            placeHolder: 'Last'
                        },
                    ]
                },
                {
                    type: 'vcomponent',
                    name: 'adding_attendees',
                    component: 'survey/Choices',
                    title: 'Is anyone else joining you for the consultation?',
                    skipClasses: true,
                    isRequired: true,
                    propsData: () => {
                        return {
                            choices: [
                                {
                                    title: 'Yes',
                                    value: true
                                },
                                {
                                    title: 'No',
                                    value: false
                                },
                            ]
                        }
                    }
                },
                {
                    type: 'panel',
                    name: 'add_attendees_panel',
                    visibleIf: '{adding_attendees} = true',
                    elements: [
                        {
                            type: 'paneldynamic',
                            name: 'attendees',
                            title: 'Please enter all attendees you would like to attend the consultation with you',
                            allowAddPanel: true,
                            allowRemovePanel: true,
                            panelRemoveButtonLocation: 'bottom',
                            panelCount: 1,
                            panelAddText: '+ Click here to add another attendee',
                            panelRemoveText: 'X Remove Attendee',
                            tabAlign: 'left',
                            templateTabTitle: '({panelIndex}) {panel.first_name}',
                            templateTitle: 'Attendee #{panelIndex}',
                            templateElements: [
                                {
                                    name: 'first_name',
                                    type: 'text',
                                    title: 'First',
                                    width: '40%',
                                    minWidth: '40%',
                                    maxWidth: '40%',
                                    isRequired: true,
                                },
                                {
                                    name: 'middle_name',
                                    type: 'text',
                                    title: 'Middle',
                                    startWithNewLine: true,
                                    width: '20%',
                                    minWidth: '20%',
                                    maxWidth: '20%',
                                },
                                {
                                    name: 'last_name',
                                    type: 'text',
                                    title: 'Last',
                                    startWithNewLine: true,
                                    width: '40%',
                                    minWidth: '40%',
                                    maxWidth: '40%',
                                    isRequired: true,
                                },
                                {
                                    type: 'vcomponent',
                                    component: 'GenericSelect',
                                    name: 'relationship',
                                    title: 'Relationship',
                                    propsData: () => {
                                        return {
                                            choices: [
                                                { text: 'Spouse', value: 'SPOUSE' },
                                                { text: 'Parent', value: 'PARENT' },
                                                { text: 'Child', value: 'CHILD' },
                                                { text: 'Friend', value: 'FRIEND' },
                                                { text: 'Other', value: 'OTHER' },
                                            ],
                                        }
                                    }
                                },
                                {
                                    name: 'caretaker',
                                    type: 'vcomponent',
                                    component: 'survey/Choices',
                                    skipClasses: true,
                                    title: 'Will {panel.first_name} need Medicare guidance?',
                                    visibleIf: '{panel.first_name} notempty',
                                    colCount: 0,
                                    startWithNewLine: true,
                                    isRequired: true,
                                    propsData: () => {
                                        return {
                                            choices: [
                                                {
                                                    value: false,
                                                    title: 'Yes'
                                                },
                                                {
                                                    value: true,
                                                    title: 'No'
                                                }
                                            ],
                                        }
                                    },
                                },
                                {
                                    type: 'vcomponent',
                                    component: 'survey/Choices',
                                    skipClasses: true,
                                    startWithNewLine: false,
                                    isRequired: true,
                                    name: 'returning',
                                    title: 'Has {panel.first_name} worked with us before?',
                                    visibleIf: '{panel.first_name} notempty AND {panel.caretaker} = false',
                                    colCount: 0,
                                },
                            ],
                        }
                    ]
                },
            ]
        },
        {
            name: 'contact_page',
            displayName: 'your_information',
            category: 'Determine your eligibility',
            hint: {
                title: 'Help Message',
                message: 'All of your information will be kept confidential and will not be shared with any third party',
            },
            elements: [
                {
                    type: 'panel',
                    name: 'client_contact_panel',
                    title: 'Your Information',
                    elements: [
                        {
                            visibleIf: '{returning} = true',
                            type: 'text',
                            name: 'email',
                            title: 'Your email address',
                            placeHolder: 'jane.doe@example.com',
                            isRequired: true,
                            validators: [{
                                type: 'email'
                            }]
                        },
                        {
                            visibleIf: '{returning} = true',
                            type: 'text',
                            name: 'dob',
                            title: 'Your date of birth',
                            startWithNewLine: true,
                            isRequired: true,
                            inputType: 'date',
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'text',
                            name: 'email',
                            title: 'Your email address',
                            placeHolder: 'jane.doe@example.com',
                            description: '{emailOptInText}',
                            isRequired: true,
                            addClasses: ['mb-3'],
                            validators: [{
                                type: 'email'
                            }]
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'panel',
                            name: 'phonePanel',
                            startWithNewLine: true,
                            addClasses: ['mb-3'],
                            elements: [
                                {
                                    type: 'text',
                                    name: 'phone',
                                    title: 'Your phone number',
                                    placeHolder: '555 444 3333 ext 123',
                                    isRequired: true,
                                    startWithNewLine: true,
                                    validators: [{
                                        type: 'text',
                                        minLength: 10,
                                    }]
                                },
                                {
                                    type: 'boolean',
                                    name: 'sms_opt_in',
                                    defaultValue: true,
                                    labelTrue: 'I allow Doctor\'s Choice to send text messages to this number',
                                    titleLocation: 'hidden'
                                }
                            ]
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'text',
                            name: 'dob',
                            title: 'Your date of birth',
                            description: 'This helps us give you accurate guidance. Your birth date will be kept confidential and will not be shared.',
                            isRequired: true,
                            inputType: 'date',
                            addClasses: ['mb-3'],
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'radiogroup',
                            name: 'gender',
                            title: 'What is your birth gender?',
                            description: 'As it\'s set on your birth certificate (required by Medicare.gov)',
                            startWithNewLine: true,
                            isRequired: true,
                            addClasses: ['mb-3'],
                            choices: [
                                {
                                    value: 'F',
                                    text: 'Female'
                                },
                                {
                                    value: 'M',
                                    text: 'Male'
                                }
                            ]
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'vcomponent',
                            name: 'location',
                            component: 'survey/Location',
                            title: 'What is your zip code?',
                            skipClasses: true,
                            pick: 'fips,zip_code,state',
                            isRequired: true,
                            addClasses: ['mb-3'],
                            propsData: () => {
                                return {
                                    inputClass: 'pb-0 mb-0',
                                    groupClass: 'pb-0 mb-0',
                                }
                            },
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'text',
                            name: 'effective_date',
                            title: 'When do you need coverage to start?',
                            startWithNewLine: true,
                            inputType: 'date',
                            addClasses: ['mb-3'],
                        },
                    ]
                },
                {
                    type: 'panel',
                    name: 'attendees_contact_panel',
                    visibleIf: '{adding_attendees} = true',
                    elements: [
                        {
                            type: 'paneldynamic',
                            allowAddPanel: false,
                            allowRemovePanel: false,
                            name: 'attendees',
                            title: 'Attendees\'s Information',
                            tabAlign: 'left',
                            templateTabTitle: '({panelIndex}) {panel.first_name}',
                            templateTitle: '{panel.first_name}\'s Information ',
                            templateElements: [
                                {
                                    visibleIf: '{panel.returning} = true',
                                    type: 'text',
                                    name: 'email',
                                    title: '{panel.first_name}\'s email address',
                                    placeHolder: 'jane.doe@example.com',
                                    isRequired: true,
                                    validators: [{
                                        type: 'email'
                                    }]
                                },
                                {
                                    visibleIf: '{panel.returning} = true AND {panel.caretaker} = false',
                                    type: 'text',
                                    name: 'dob',
                                    title: 'What is {panel.first_name}\'s date of birth?',
                                    startWithNewLine: true,
                                    isRequired: true,
                                    inputType: 'date',
                                },
                                {
                                    visibleIf: '{panel.returning} = false OR {panel.caretaker} = true',
                                    type: 'text',
                                    name: 'email',
                                    title: '{panel.first_name}\'s email address',
                                    placeHolder: 'jane.doe@example.com',
                                    description: 'You can leave it blank if it\'s the same as yours. {emailOptInText}',
                                    addClasses: ['mb-3'],
                                    validators: [{
                                        type: 'email'
                                    }]
                                },
                                {
                                    visibleIf: '{panel.returning} = false OR {panel.caretaker} = true',
                                    type: 'panel',
                                    name: 'phonePanel',
                                    startWithNewLine: true,
                                    addClasses: ['mb-3'],
                                    elements: [
                                        {
                                            type: 'text',
                                            name: 'phone',
                                            title: '{panel.first_name}\'s phone number',
                                            isRequired: true,
                                            placeHolder: '555 444 3333 ext 123',
                                            validators: [{
                                                type: 'text',
                                                minLength: 10,
                                            }]
                                        },
                                        {
                                            visibleIf: '{panel.caretaker} = false',
                                            type: 'boolean',
                                            name: 'sms_opt_in',
                                            defaultValue: true,
                                            labelTrue: '{panel.first_name} allows Doctor\'s Choice to send text messages to this number',
                                            titleLocation: 'hidden'
                                        }
                                    ]
                                },
                                {
                                    visibleIf: '{panel.returning} = false AND {panel.caretaker} = false',
                                    type: 'text',
                                    name: 'dob',
                                    title: 'What is {panel.first_name}\'s date of birth?',
                                    description: 'This helps us give you accurate guidance. {panel.first_name}\'s birth date will be kept confidential and will not be shared.',
                                    isRequired: true,
                                    inputType: 'date',
                                    addClasses: ['mb-3'],
                                },
                                {
                                    visibleIf: '{panel.returning} = false AND {panel.caretaker} = false',
                                    type: 'radiogroup',
                                    name: 'gender',
                                    title: 'What is {panel.first_name}\'s birth gender?',
                                    description: 'As it\'s set on {panel.first_name}\'s birth certificate (required by Medicare.gov)',
                                    startWithNewLine: true,
                                    isRequired: true,
                                    addClasses: ['mb-3'],
                                    choices: [
                                        {
                                            value: 'F',
                                            text: 'Female'
                                        },
                                        {
                                            value: 'M',
                                            text: 'Male'
                                        }
                                    ]
                                },
                                {
                                    visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                                    type: 'vcomponent',
                                    name: 'location',
                                    component: 'survey/Location',
                                    title: '{panel.first_name}\'s Zip Code',
                                    skipClasses: true,
                                    pick: 'fips,zip_code,state',
                                    description: 'You can leave it blank if it\'s the same as yours',
                                    addClasses: ['mb-3'],
                                    propsData: () => {
                                        return {
                                            required: false,
                                            inputClass: 'pb-0 mb-0',
                                            groupClass: 'pb-0 mb-0',
                                        }
                                    },
                                },
                                {
                                    visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                                    type: 'text',
                                    name: 'effective_date',
                                    title: 'When does {panel.first_name} need coverage to start?',
                                    startWithNewLine: true,
                                    inputType: 'date',
                                    description: 'You can leave it blank if it\'s the same as yours',
                                    addClasses: ['mb-3'],
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'verification',
            elements: [
                {
                    type: 'panel',
                    name: 'returning_token_panel',
                    elements: [
                        {
                            titleLocation: 'hidden',
                            type: 'vcomponent',
                            name: 'returning_tokens',
                            component: 'schedule/Token',
                            propsData: ({ question }) => {
                                return {
                                    client: question.survey.valuesHash,
                                    attendees: question.survey.valuesHash.attendees || [],
                                    addingAttendees: question.survey.valuesHash.adding_attendees,
                                    autoAdvance: true,
                                }
                            },
                        }
                    ]
                }
            ]
        },
        {
            name: 'special_page',
            title: 'Special Circumstances',
            displayName: 'Special Circumstances',
            // hint: {
            //     title: 'Help Message',
            //     message: 'This is an example of some explanation of feature, data we collect',
            //     link: 'some link'
            // },
            elements: [
                {
                    type: 'panel',
                    name: 'special_panel',
                    title: 'Do any of the following apply to you? Check all that apply.',
                    visibleIf: '{returning} = false',
                    elements: [
                        {
                            type: 'panel',
                            name: 'referral_panel',
                            visibleIf: '{returning} = false',
                            elements: [
                                {
                                    visibleIf: '{returning} = false',
                                    name: 'source_type',
                                    title: 'Were you referred to us by an/a',
                                    type: 'vcomponent',
                                    component: 'GenericSelect',
                                    skipClasses: true,
                                    isRequired: true,
                                    propsData: () => {
                                        return {
                                            url: '/api/consults/survey/source-types/'
                                        }
                                    }
                                },
                                {
                                    visibleIf: '{returning} = false AND ({source_type} = "FRIEND" OR {source_type} = "FAMILY_MEMBER" OR {source_type} = "FINANCIAL_ADVISOR" OR {source_type} = "DOCTOR" OR {source_type} = "OTHER" OR ({source_type} = "EMPLOYER" AND {cannotAgent}))',
                                    type: 'text',
                                    name: 'source_name',
                                    title: 'Please specify',
                                    isRequired: false,
                                    disabledIf: '{source_opt_out} = true',
                                    validators: [{
                                        type: 'text',
                                        maxLength: 100,
                                    }]
                                },
                                {
                                    visibleIf: '{returning} = false AND {source_type} = "FAMILY_FIRST"',
                                    type: 'text',
                                    name: 'source_name',
                                    title: 'Please provide the name of advocate that referred you',
                                    isRequired: false,
                                    disabledIf: '{source_opt_out} = true',
                                    validators: [{
                                        type: 'text',
                                        maxLength: 100,
                                    }]
                                },
                                {
                                    type: 'boolean',
                                    name: 'source_opt_out',
                                    labelTrue: 'I prefer not to specify my referral information',
                                    titleLocation: 'Hidden',
                                    defaultValue: false,
                                    valueTrue: true,
                                    valueFalse: false,
                                },
                                {
                                    visibleIf: '{returning} = false AND {source_type} != "EMPLOYER" AND {canAgent}',
                                    type: 'vcomponent',
                                    name: 'source_client',
                                    component: 'schedule/ClientSelect',
                                    skipClasses: true,
                                    title: 'Referral Client',
                                },
                                {
                                    visibleIf: '{canAgent} and {source_client} notempty',
                                    type: 'html',
                                    name: 'source_client_id',
                                    html: '<pre>Referral Client ID: {source_client}</pre>'
                                },
                                {
                                    visibleIf: '{returning} = false AND {source_type} = "EMPLOYER" AND {canAgent}',
                                    type: 'vcomponent',
                                    name: 'group',
                                    component: 'schedule/GroupSelect',
                                    skipClasses: true,
                                    title: 'Employer group',
                                },
                                {
                                    visibleIf: '{canAgent} and {group} notempty',
                                    type: 'html',
                                    name: 'group_id',
                                    html: '<pre>Group ID: {group}</pre>'
                                },
                                {
                                    visibleIf: '{returning} = false AND {source_type} = "EMPLOYER" AND {canAgent}',
                                    type: 'text',
                                    name: 'source_name',
                                    title: 'Can\'t find your group? Enter it manually',
                                },
                                {
                                    visibleIf: '{returning} = false AND {source_type} = "EMPLOYER" AND {canAgent}',
                                    type: 'vcomponent',
                                    component: 'GenericSelect',
                                    name: 'group_relationship',
                                    title: 'Group relationship',
                                    propsData: () => {
                                        return {
                                            choices: [
                                                { text: 'Employee', value: 'EMPLOYEE' },
                                                { text: 'Spouse of employee', value: 'EMPLOYEE_SPOUSE' },
                                                { text: 'Dependant of employee', value: 'EMPLOYEE_DEPENDENT' },
                                                { text: 'Parent of employee', value: 'EMPLOYEE_PARENT' },
                                                { text: 'Other relative of employee', value: 'OTHER_RELATIVE' },
                                                { text: 'Acquaintance of employee', value: 'ACQUAINTANCE_OF_EMPLOYEE' },
                                                { text: 'Referred by client', value: 'REFERRED_BY_CLIENT' },
                                            ]
                                        }
                                    }
                                },
                            ]
                        },
                        {
                            visibleIf: '{returning} = false',
                            name: 'hsa_account',
                            titleLocation: 'hidden',
                            type: 'boolean',
                            labelTrue: 'I contribute to a Health Savings Account(HSA)',
                            tooltip: 'A health savings account, or HSA, is a type of savings account that lets you set aside money on a pre-tax basis to pay for qualified medical expenses',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        // {
                        //     visibleIf: '{returning} = false and {hsa_account} = true',
                        //     type: 'boolean',
                        //     name: 'hsa',
                        //     labelTrue: 'I am actively contributing to my HSA account',
                        //     titleLocation: 'hidden',
                        //     defaultValue: false,
                        //     valueTrue: true,
                        //     valueFalse: false,
                        //     addClasses: ['border', 'rounded']
                        // },
                        {
                            visibleIf: '{returning} = false',
                            type: 'boolean',
                            name: 'collecting_ssi',
                            labelTrue: 'I am collecting Social Security or Railroad Retirement benefits',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'boolean',
                            name: 'multiple_states',
                            labelTrue: 'I plan on moving to a different state within the next 12 months.',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            name: 'moving_state',
                            type: 'vcomponent',
                            component: 'GenericSelect',
                            title: 'Which state are you moving to?',
                            visibleIf: '{returning} = false AND {multiple_states} = true',
                            propsData: () => {
                                return {
                                    url: '/api/consults/survey/states/',
                                }
                            },
                            isRequired: true
                        },
                        {
                            visibleIf: '{returning} = false',
                            type: 'boolean',
                            name: 'moving',
                            labelTrue: 'I plan on traveling out of state within the next 12 months',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },

                    ]
                },
                {
                    name: 'attendees',
                    visibleIf: '{adding_attendees} = true AND {hasOtherNewAttendees} = true',
                    type: 'paneldynamic',
                    allowAddPanel: false,
                    allowRemovePanel: false,
                    _title: 'Do any of the following apply to {panel.first_name}? Check all that apply.',
                    tabAlign: 'left',
                    templateTitle: `Do any of the following apply to {panel.first_name}? Check all that apply.${templateTitleDebug}`,
                    _templateTitle: `{panel.first_name} {panel.last_name}${templateTitleDebug}`,
                    templateElements: [
                        {
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                            type: 'boolean',
                            name: 'hsa_account',
                            labelTrue: '{panel.first_name} contributes to a Health Savings Account(HSA)',
                            tooltip: 'A health savings account, or HSA, is a type of savings account that lets you set aside money on a pre-tax basis to pay for qualified medical expenses',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false AND {panel.hsa_account} = true',
                            type: 'boolean',
                            name: 'hsa',
                            labelTrue: '{panel.first_name} is actively contributing to their HSA account',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                            type: 'boolean',
                            name: 'collecting_ssi',
                            labelTrue: '{panel.first_name} is collecting Social Security or Railroad Retirement benefits',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                            type: 'boolean',
                            name: 'multiple_states',
                            labelTrue: '{panel.first_name} is planning on moving to a different state within the next 12 months.',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false',
                            type: 'boolean',
                            name: 'moving',
                            labelTrue: '{panel.first_name} is planning on traveling out of state within the next 12 months',
                            titleLocation: 'hidden',
                            defaultValue: false,
                            valueTrue: true,
                            valueFalse: false,
                            addClasses: ['border', 'rounded']
                        },
                        {
                            type: 'vcomponent',
                            component: 'GenericSelect',
                            name: 'moving_state',
                            title: 'Which state is {panel.first_name} moving to?',
                            visibleIf: '{panel.caretaker} = false AND {panel.returning} = false AND {panel.moving} = true',
                            propsData: () => {
                                return {
                                    url: '/api/consults/survey/states/',
                                }
                            },
                            isRequired: true
                        },
                    ],
                }
            ]
        },
        {
            name: 'contact_method',
            displayName: 'Contact Method',
            title: 'Contact Method',
            elements: [
                {
                    type: 'panel',
                    name: 'method_panel',
                    elements: [
                        {
                            visibleIf: '{aep} != true',
                            title: 'What is your preferred contact method for the consultation?',
                            type: 'vcomponent',
                            name: 'method',
                            component: 'survey/Choices',
                            isRequired: true,
                            skipClasses: true,
                            propsData: () => {
                                return {
                                    choices: [
                                        { title: 'E-mail', value: 'email', icon: 'envelope' },
                                        { title: 'Phone', value: 'phone', icon: 'phone' },
                                        { title: 'Video', value: 'video', icon: 'video' }
                                    ]
                                }
                            }
                        },
                        {
                            visibleIf: '{aep} = true',
                            title: 'What is your preferred contact method for the consultation?',
                            type: 'vcomponent',
                            name: 'method',
                            component: 'survey/Choices',
                            isRequired: true,
                            skipClasses: true,
                            propsData: () => {
                                return {
                                    choices: [
                                        { title: 'E-mail', value: 'email', icon: 'envelope' },
                                        { title: 'Phone', value: 'phone', icon: 'phone' },
                                        { title: 'AEP Survey', value: 'survey', icon: 'link' },
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "email" and {aep} != true',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('email'),
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "email" and {aep} = true',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('email_in_aep'),
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "phone" and {aep} != true',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('phone'),
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "phone" and {aep} = true',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('phone_in_aep'),
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "video"',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('video'),
                },
                {
                    titleLocation: 'hidden',
                    type: 'vcomponent',
                    name: 'contact_hint',
                    component: 'WizardHelp',
                    visibleIf: '{method} = "survey" and {aep} = true',
                    skipClasses: true,
                    propsData: getContactWizardHelpPropsData('survey_in_aep'),
                },
                {
                    titleLocation: 'hidden',
                    visibleIf: '{method} = "survey"',
                    type: 'vcomponent',
                    title: '{bookingTitle}',
                    name: 'book',
                    component: 'schedule/Book',
                    propsData: getBookPropsData(),
                },
                {
                    titleLocation: 'hidden',
                    visibleIf: '{method} = "email"',
                    type: 'vcomponent',
                    title: '{bookingTitle}',
                    name: 'book',
                    component: 'schedule/Book',
                    propsData: getBookPropsData(),
                }
            ]
        },
        {
            visibleIf: '{method} != "survey" and {method} != "email"',
            name: 'bookPage',
            displayName: 'Booking',
            elements: [
                {
                    // titleLocation: 'hidden',
                    type: 'vcomponent',
                    title: '{bookingTitle}',
                    name: 'book',
                    component: 'schedule/Book',
                    propsData: getBookPropsData(),
                }
            ]
        }
    ],
    triggers: []
}

export default surveyJson
