

export default {
    props: {
        clientId: {
            type: Number,
            default: null
        },
        visitOnClick: {
            type: Boolean,
            default: true
        },
        days: {
            type: Number,
            default: 60
        },
        title: {
            type: String,
            default: 'Signed Pre-Consult SOA in the past 60 days:'
        },
    },
    data () {
        return {
            soas: [],
            total: 0,
            form: {
                days: this.days,
                query: this.$route.query.query || '',
                page: parseInt(this.$route.query.page) || 1,
                size: parseInt(this.$route.query.size) || 3
            },
        }
    },
    async fetch () {
        await this.search()
    },
    watch: {
        clientId (v) {
            this.form.page = 1
            this.search()
        },
        days (v) {
            this.form.days = v
            this.search()
        },
        'form.page' (v) {
            this.search()
        },
    },
    methods: {
        async search () {
            const { items, total, error, page } = await this.$try('getSignedSoas', this.clientId, {
                days: this.form.days,
                page: this.form.page,
                size: this.form.size
            })
            if (!error) {
                this.soas = items
                this.total = total || 0
                this.form.page = page
            }
        },
        onClick ({ event, soa }) {
            if (!this.visitOnClick) {
                event.preventDefault()
                event.stopPropagation()
            }
            this.$emit('click', { event, soa })
            return true
        }
    }
}
