import { render, staticRenderFns } from "./EmployerReport.vue?vue&type=template&id=2ea1b942&lang=pug&"
import script from "./EmployerReport.vue?vue&type=script&lang=js&"
export * from "./EmployerReport.vue?vue&type=script&lang=js&"
import style0 from "./EmployerReport.vue?vue&type=style&index=0&id=2ea1b942&prod&lang=scss&__scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Editable: require('/codebuild/output/src3812392186/src/frontend/components/Editable.vue').default,ChartBars: require('/codebuild/output/src3812392186/src/frontend/components/chart/Bars.vue').default,Icon: require('/codebuild/output/src3812392186/src/frontend/components/Icon.vue').default})
