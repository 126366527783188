import { render, staticRenderFns } from "./ClientForm.vue?vue&type=template&id=5ba8bd78&lang=pug&"
import script from "./ClientForm.vue?vue&type=script&lang=js&"
export * from "./ClientForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateTimeInput: require('/codebuild/output/src3812392186/src/frontend/components/DateTimeInput.vue').default,Location: require('/codebuild/output/src3812392186/src/frontend/components/Location.vue').default,Icon: require('/codebuild/output/src3812392186/src/frontend/components/Icon.vue').default,ClientSelect: require('/codebuild/output/src3812392186/src/frontend/components/ClientSelect.vue').default,GroupSelect: require('/codebuild/output/src3812392186/src/frontend/components/GroupSelect.vue').default})
