
import { mapGetters } from 'vuex'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    autosave: true,
    data () {
        return {
            form: {
                notes: null,
            },
            schedulingContexts: [],
        }
    },
    computed: {
        ...mapGetters([
            'uid',
            'survey',
        ]),
    },
    async mounted () {
        const contexts = await this.$api.getRecentSchedulerActivitiesContexts(this.uid, { limit: 3 })
        this.schedulingContexts = [].concat(contexts.filter(c => c?.data?.notes))
    },
}
