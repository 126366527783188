import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=b96369fa&lang=pug&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=b96369fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EdgeToggle: require('/codebuild/output/src3812392186/src/frontend/components/EdgeToggle.vue').default,AutosaveStatus: require('/codebuild/output/src3812392186/src/frontend/components/AutosaveStatus.vue').default,Name: require('/codebuild/output/src3812392186/src/frontend/components/Name.vue').default,Icon: require('/codebuild/output/src3812392186/src/frontend/components/Icon.vue').default,SurveyQuickEditForm: require('/codebuild/output/src3812392186/src/frontend/components/SurveyQuickEditForm.vue').default,RelatedClientSelect: require('/codebuild/output/src3812392186/src/frontend/components/RelatedClientSelect.vue').default,MatchingClientsAlert: require('/codebuild/output/src3812392186/src/frontend/components/MatchingClientsAlert.vue').default})
