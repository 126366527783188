
import _ from 'lodash'

export default {
    props: {
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            sendMeACopy: false,
            form: {
                to: this.client?.email || '',
                subject: 'Get ready for your consultation',
                skip_soa: false,
            },
        }
    },
    async fetch () {},
    computed: {
        emails () {
            return (this.form.to || '').split(',').map(to => to.trim()).filter(to => !!to)
        },
    },
    watch: {
        sendMeACopy (v) {
            let to = (this.form?.to || '').split(',')
            if (v === true) {
                to.push(this.user.email)
                to = _.uniq(to)
            } else {
                _.remove(to, e => _.toLower(e) === _.toLower(this.user.email))
            }
            this.$set(this.form, 'to', to.join(','))
        },
    },
    methods: {
        async send () {
            const { error } = await this.$try('sendPreConsultSurvey', {
                id: this.client.id,
                data: {
                    ...this.form,
                    to: this.emails
                }
            })
            if (!error) {
                this.$success('Pre-Consult survey sent!')
                this.$emit('success')
            }
        },
    },
}
