import moment from 'moment'
import domtoimage from 'dom-to-image-more'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { money, yesno, titlelize, titlelizeList, stat, summarizeList, toUpperList, pluralize, name, toQuerystring } from '~/util/string'
import downloadBase64 from '~/util/base64'

export default {
    beforeRouteLeave (to, from, next) {
        this.$root.$emit('bv::hide::tooltip')
        if (typeof window !== 'undefined' && window.document) {
            Array.from(window.document.querySelectorAll('.modal.show'))
                .map(el => el.id)
                .forEach((id) => {
                    this.$root.$emit('bv::hide::modal', id)
                })
        }
        next()
    },
    computed: {
        ...mapGetters([
            'hostUrl',
            'effectiveHostUrl',
            'isServer',
            'lightTheme',
            'darkTheme',
            'edge',
            'user',
            'group',
            'asGroup',
            'inGroup',
            'isSize',
            's3Base',
            'isClient',
            'isHr',
            'isAgent',
            'isAdmin',
            'isSu',
            'canClient',
            'canHr',
            'canAgent',
            'canAdmin',
            'canSu',
            'isAgentMode',
        ]),
        currentLayout () {
            // tacky
            return _.find(this.$root.$children, vm => vm?.$refs?.body)
        },
        header () {
            return this?.currentLayout?.$refs?.header
        },
        body () {
            return this?.currentLayout?.$refs?.body
        },
        footer () {
            return this?.currentLayout?.$refs?.footer
        }
    },
    data () {
        return { uuid: uuidv4() }
    },
    methods: {
        noop () {},
        setGlobal (key, value) {
            if (typeof window !== 'undefined') {
                window[key] = value
            } else {
                global[key] = value
            }
        },
        setBusy () {
            this.$store.dispatch('busy')
        },
        setIdle () {
            this.$store.dispatch('idle')
        },
        trace (...args) {
            console.trace(...args)
            return args[0]
        },
        inspect (...args) {
            /* eslint-disable */
            console.trace(...args)
            debugger
            return args[0]
        },
        dateFormat(date, format) {
            return moment(date).format(format)
        },
        focus(selector) {
            const el = this.$el.querySelector(selector)
            if (el) {
                setTimeout(() => el.focus(), 0)
            }
        },
        toName: name,
        stat,
        yesno,
        money,
        pluralize,
        titlelize,
        titlelizeList,
        summarizeList,
        toUpperList,
        toQuerystring,
        downloadBase64,
        timeout (fn, ms) {
            return setTimeout(fn, ms)
        },
        replaceTrailingSlash(u) {
            return (u || '').replace(/\/$/, '')
        },
        sentryCaptureMessage (message, options = {}) {
            options = options || {}
            const level = options.level || 'warning'
            const contexts = options.contexts || {}
            const tags = options.tags || {}
            if (this.$sentry) {
                this.$sentry.captureMessage(message, { level, contexts, tags })
                if (options.screenshot) {
                    setTimeout(() => this.captureShot(message), 1000)
                }
            }
        },
        sentryBreadCrumb (message, options = {}) {
            const category = options.category
            const level = options.level || 'info'
            const data = options.data || {}
            if (this.$sentry) {
                this.$sentry.addBreadcrumb({
                    message,
                    category,
                    level,
                    data: {
                        ...data
                    },
                })
            }
        },
        async captureShot (message) {
            const blob = await domtoimage.toBlob(document.body)
            const file = new File([blob], `${message}.png`)
            const form = new FormData()
            form.append('files', file)
            console.log('uploading', file)
            const res = await this.$api.uploadSentryShot(form)
            console.log('uploaded', res)
        },
    }
}
