
import { mapGetters } from 'vuex'
export default {
    async fetch () {
        if (this.uid) {
            const resp = await this.$try('getRelatedClients', this.uid, { spinner: false })
            if (resp?.clients) {
                const clients = resp?.clients.map((c) => {
                    c.relationship = resp?.spouse?.id === c.id ? 'spouse' : 'other'
                    return c
                })
                this.$store.commit('clients', clients)
            }
        }
    },
    computed: {
        ...mapGetters(['client', 'clients', 'uid'])
    },
    methods: {
        async confirmDeleteRelatedClient (id) {
            const value = await this.$bvModal.msgBoxConfirm('This will delete the automatic association of the clients, this is NOT the Spouse field that you set in Salesforce', {
                title: 'Are you sure you want to remove the relation?',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                footerClass: 'p-2',
                centered: true
            })
            if (value) {
                await this.$try('deleteRelatedClient', { id1: this.uid, id2: id })
                await this.$fetch()
            }
        }
    }
}
