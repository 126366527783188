import { render, staticRenderFns } from "./PlanProvidersList.vue?vue&type=template&id=647d97d8&lang=pug&"
import script from "./PlanProvidersList.vue?vue&type=script&lang=js&"
export * from "./PlanProvidersList.vue?vue&type=script&lang=js&"
import style0 from "./PlanProvidersList.vue?vue&type=style&index=0&id=647d97d8&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src3812392186/src/frontend/components/Icon.vue').default,Name: require('/codebuild/output/src3812392186/src/frontend/components/Name.vue').default})
