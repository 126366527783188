import { render, staticRenderFns } from "./ConsultationScripts.vue?vue&type=template&id=20057754&scoped=true&lang=pug&"
import script from "./ConsultationScripts.vue?vue&type=script&lang=js&"
export * from "./ConsultationScripts.vue?vue&type=script&lang=js&"
import style0 from "./ConsultationScripts.vue?vue&type=style&index=0&id=20057754&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20057754",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/codebuild/output/src3812392186/src/frontend/components/Spinner.vue').default})
