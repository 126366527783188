import Vue from 'vue'
import VueGtag from 'vue-gtag'
import vueJsonEditor from 'vue-json-editor'
import VueSignaturePad from 'vue-signature-pad'

Vue.use(VueSignaturePad)

// https://matteo-gabriele.gitbook.io/vue-gtag/v/master/api
Vue.use(VueGtag, {
    config: {
        id: 'G-4104YDHVSZ',
        params: {
            send_page_view: true
        }
    }
})

Vue.component('VueJsonEditor', vueJsonEditor)

export default async (context) => {
    await context.store.dispatch('nuxtClientInit', context)
}
