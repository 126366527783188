import { render, staticRenderFns } from "./Turning65Medicare.vue?vue&type=template&id=af328b50&lang=pug&"
import script from "./Turning65Medicare.vue?vue&type=script&lang=js&"
export * from "./Turning65Medicare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Anchor: require('/codebuild/output/src3812392186/src/frontend/components/Anchor.vue').default})
