import { render, staticRenderFns } from "./IncomeSurcharges.vue?vue&type=template&id=92a3ca5a&lang=pug&"
import script from "./IncomeSurcharges.vue?vue&type=script&lang=js&"
export * from "./IncomeSurcharges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IncomeCalculator: require('/codebuild/output/src3812392186/src/frontend/components/IncomeCalculator.vue').default})
