import { render, staticRenderFns } from "./Editable.vue?vue&type=template&id=17de31fd&lang=pug&"
import script from "./Editable.vue?vue&type=script&lang=js&"
export * from "./Editable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src3812392186/src/frontend/components/Icon.vue').default,Editor: require('/codebuild/output/src3812392186/src/frontend/components/Editor.vue').default})
